
  import Vue from 'vue'
  import { defineComponent, ref, watch } from "vue"

  export default defineComponent({
    props: {
      initialValue: {
        type: Number,
        required: false
      },
      checkboxDisabled: {
        type: Boolean,
        default: false
      },
      active: {
        type: Boolean,
        default: true
      }
    },
    setup(props, { emit }) {
      const value = ref()
      const enabled = ref(props.active)

      watch(enabled, (newValue) => {
        if (!newValue) {
          value.value = undefined
        } else {
          value.value = props.initialValue
        }
      }, {immediate: true})

      watch(value, (updatedValue) => {
        emit('input', updatedValue)
      })

      return {
        value,
        enabled
      }
    }
  })
